<template>
    <CModal
      :title="title"
      :color="color"
      :show.sync="showModal"
    >   
        <CForm>
            <CRow>
              <CCol md="6">
                <CSelect
                    label="Categoria:"
                    :value.sync="$v.form.asset_category_id.$model"
                    :isValid="checkIfValid('asset_category_id')"
                    :options="categoriesOptions"
                    invalidFeedback="Este campo es requerido."
                  />                            
              </CCol>
              <CCol md="6">
                <CSelect
                  label="Condición:"
                  :value.sync="$v.form.asset_condition_id.$model"
                  :isValid="checkIfValid('asset_condition_id')"
                  :options="conditionsOptions"
                  invalidFeedback="Este campo es requerido."
                />                              
              </CCol>
              <CCol md="12">
                <CInput
                  label="Nombre:"
                  :lazy="false"
                  :value.sync="$v.form.name.$model"
                  :isValid="checkIfValid('name')"
                  placeholder=""
                  autocomplete="given-name"
                  invalidFeedback="Este campo es requerido y debe ser mayor a 2 caracteres."
                />
              </CCol>
              <CCol md="12">
                <CInput
                  label="Descripción:"
                  :lazy="false"
                  :value.sync="$v.form.description.$model"
                  placeholder=""
                  autocomplete="given-description"
                />
              </CCol>            
            </CRow>
            <CRow>         
              <CCol md="12">
                <CInput
                  label="Serie:"
                  :lazy="false"
                  :value.sync="$v.form.serie.$model"
                  placeholder=""
                  autocomplete="given-description"
                />
              </CCol>
              <CCol md="6">
                <CSelect
                  label="Lugar:"
                  :value.sync="$v.form.asset_location_id.$model"
                  :isValid="checkIfValid('asset_location_id')"
                  :options="locationsOptions"
                  invalidFeedback="Este campo es requerido."
                />                        
              </CCol>                     
              <CCol md="6">
                <CSelect
                  label="Departamento:"
                  :value.sync="$v.form.asset_departament_id.$model"
                  :isValid="checkIfValid('asset_departament_id')"
                  :options="departamentsOptions"
                  invalidFeedback="Este campo es requerido."
                />                              
              </CCol>
            </CRow>
        </CForm>
        <template #footer>
          <CButton @click="closeModal" color="secondary">Cancelar</CButton>
          <CButton @click="store" :color="color">Aceptar</CButton>
        </template>
    </CModal>
</template>

<script>
import { validationMixin } from "vuelidate"
import { required, minLength, minValue, helpers } from "vuelidate/lib/validators"
import ws_cat from '../../services/asset_categories';
import ws_con from '../../services/asset_conditions';
import ws_dep from '../../services/asset_departaments';
import ws_loc from '../../services/asset_locations';
import ws_per from '../../services/asset_persons';

export default {
  name: 'AssetModal',
  props: {

  },
  data () {
    return {
      showModal: false,
      title: "Nuevo Activo",
      color: "info",
      isEdit: false,
      form: this.getEmptyForm(),
      submitted: false,      
      categoriesOptions: [],
      departamentsOptions: [],
      conditionsOptions: [],
      personsOptions: [],
      locationsOptions: [],
    }
  },
  mounted: async function() {    
    let response_cat = await ws_cat.get();
    if(response_cat.type == "success"){
      this.categoriesOptions = await this.$parseSelectOptionsOrdered(response_cat.data, "id", "name",[]);
    }
    let response_dep = await ws_dep.get();
    if(response_dep.type == "success"){
      this.departamentsOptions = await this.$parseSelectOptionsOrdered(response_dep.data, "id", "name",[]);
    }
    let response_con = await ws_con.get();
    if(response_con.type == "success"){
      this.conditionsOptions = await this.$parseSelectOptionsOrdered(response_con.data, "id", "name",[]);
    }
    let response_loc = await ws_loc.get();
    if(response_loc.type == "success"){
      this.locationsOptions = await this.$parseSelectOptionsOrdered(response_loc.data, "id", "name",[]);
    }
    let response_per = await ws_per.get();
    if(response_per.type == "success"){
      this.personsOptions = await this.$parseSelectOptionsOrdered(response_per.data, "id", "name",[]);
    }

  },
  methods: {
    storeModal () {
      this.showModal = true;
      this.color = "info";
      this.title = 'Nuevo Activo';
      this.isEdit = false;
      this.submitted = false;

      this.form = {
          id: 0,
          name: '',
          description: '',
          serie: "",
          asset_category_id: this.categoriesOptions[0].value,
          asset_departament_id: this.departamentsOptions[0].value,
          asset_condition_id: this.conditionsOptions[0].value,
          asset_location_id: this.locationsOptions[0].value,
      }
    },
    updateModal (item) {
      this.showModal = true;
      this.color = "warning";
      this.title = 'Editar Activo';
      this.isEdit = true;
      this.submitted = false;

      this.form = {
          id: item.id,
          name: item.name,
          description: item.description,
          serie: item.serie,
          asset_category_id: item.asset_category_id,
          asset_departament_id: item.asset_departament_id,
          asset_condition_id: item.asset_condition_id,
          asset_person_id: item.asset_person_id,
          asset_location_id: item.asset_location_id,
      };
    },
    store () {
      this.$v.$touch();

      if (this.isValid) {
        this.submitted = true;

        if(this.form.id > 0){
          this.$emit("update", this.form );
        }
        else{
          this.$emit("store", this.form );
        }

        this.closeModal();
      }
    },
    closeModal () {
      this.showModal = false;

      this.form = this.getEmptyForm()
      this.submitted = false
      this.$v.$reset()
    },
    getEmptyForm () {
      return {
          id: 0,
          name: '',
          description: '',
          serie: "",
          asset_category_id: "",
          asset_departament_id: "",
          asset_condition_id: "",
          asset_location_id: "",
      }
    },
    checkIfValid (fieldName) {
      const field = this.$v.form[fieldName]
      if (!field.$dirty) {
        return null
      }
      
      return !(field.$invalid || field.$model === '')
    },
  },
  computed: {
    formString () { return JSON.stringify(this.form, null, 4) },
    isValid () { return !this.$v.form.$invalid },
    isDirty () { return this.$v.form.$anyDirty },
  },
  mixins: [validationMixin],
  validations: {
    form: {
      name: {
        required,
        minLength: minLength(3)
      },
      description: {        
      },
      serie: {        
      },
      asset_category_id: {
        required,
        minValue: minValue(1)
      },
      asset_location_id: {
        required,
        minValue: minValue(1)
      },
      asset_departament_id: {
        required,
        minValue: minValue(1)
      },
      asset_condition_id: {
        required,
        minValue: minValue(1)
      },
    }
  },
}
</script>