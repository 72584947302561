import axios from 'axios'
import store from '../store';

export default {
    get: (filters) => axios.post('assets',filters),
    getByCategory: (category) => axios.post('assets',{category: category}),
    store: (data) => axios.post('assets/store', data),
    update: (data) => axios.post('assets/update', data),
    delete: (id) => axios.post('assets/delete', id),
    downloadExcel: (filters) => axios.post('assets/download-excel',filters).then(function(response){
        if(response.type == "success"){
            window.open(response.data, '_blank');
        }
        else{
            store.commit('toast', {color: response.type, message: response.message});
        }
    }),
    downloadRecord: (data) => axios.post('assets/download-record',data).then(function(response){
        if(response.type == "success"){
            window.open(response.data, '_blank');
        }
        else{
            store.commit('toast', {color: response.type, message: response.message});
        }
    }),
    initTags: (quantity) => axios.post('assets/init-tags',{quantity: quantity}).then(function(response){
        if(response.type == "success"){
            window.open(response.data, '_blank');
        }
        else{
            store.commit('toast', {color: response.type, message: response.message});
        }
    }),
    downloadTags: (filters) => axios.post('assets/download-tags',filters).then(function(response){
        if(response.type == "success"){
            window.open(response.data, '_blank');
        }
        else{
            store.commit('toast', {color: response.type, message: response.message});
        }
    }),
    downloadTagsMini: (filters) => axios.post('assets/download-tags-mini',filters).then(function(response){
        if(response.type == "success"){
            window.open(response.data, '_blank');
        }
        else{
            store.commit('toast', {color: response.type, message: response.message});
        }
    }),
    getQrAsset: (code) => axios.post('qr-asset', {code: code}),
    getPublicQrAsset: (code) => axios.post('qr-public-asset', {code: code}),   
}