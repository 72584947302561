<template>
    <CModal
      :title="title"
      :color="color"
      :show.sync="showModal"
    >   
        <CForm>
            <CRow>
              <CCol md="12">
                <CInput
                  label="Descripción:"
                  :lazy="false"
                  :value.sync="$v.form.description.$model"
                  placeholder=""
                  autocomplete="given-description"
                />
                <CInput
                :lazy="false"
                :value.sync="$v.form.asset_id.$model"
                hidden
              />
              </CCol>            
            </CRow>
            <CRow>         
              <CCol md="6">
                <div role="group" class="form-group">
                  <label> Fecha:</label>
                  <v-date-picker
                    class="date-picker"
                    v-model="$v.form.date.$model"
                    :isValid="checkIfValid('date')"
                    locale="es-MX" size="sm"
                  />
                </div>
              </CCol>                   
              <CCol md="6">
                <CSelect
                    label="Persona:"
                    :value.sync="$v.form.asset_person_id.$model"
                    :isValid="checkIfValid('asset_person_id')"
                    :options="personsOptions"
                    invalidFeedback="Este campo es requerido."
                  />                            
              </CCol>                                                             
            </CRow>
        </CForm>
        <template #footer>
          <CButton @click="closeModal" color="secondary">Cancelar</CButton>
          <CButton @click="store" :color="color">Aceptar</CButton>
        </template>
    </CModal>
</template>

<script>
import { validationMixin } from "vuelidate"
import { required, minLength, minValue, helpers } from "vuelidate/lib/validators"
import ws_per from '../../services/asset_persons';
import moment from 'moment';
import { DatePicker } from 'v-calendar'
import moment_timezone from 'moment-timezone';

export default {
  name: 'AssetAssignModal',
  props: {
  },
  components: {
    'v-date-picker': DatePicker
  },
  data () {
    return {
      showModal: false,
      title: "Asignar Persona",
      color: "info",
      isEdit: false,
      form: this.getEmptyForm(),
      submitted: false,      
      personsOptions: [],
    }
  },
  mounted: async function() {    
    let response_per = await ws_per.get();
    if(response_per.type == "success"){
      this.personsOptions = await this.$parseSelectOptionsOrdered(response_per.data, "id", "name",[]);
    }

  },
  methods: {
    storeModal (item) {
      this.showModal = true;
      this.color = "info";
      this.title = 'Asignar Persona';
      this.isEdit = false;
      this.submitted = false;

      this.form = {
          id: 0,
          description: "",
          date: moment().toDate(),
          asset_id: item.id,
          asset_person_id: this.personsOptions[0].value,
      }
    },
    updateModal (item) {
      this.showModal = true;
      this.color = "warning";
      this.title = 'Editar Activo';
      this.isEdit = true;
      this.submitted = false;

      this.form = {
          id: item.id,
          date: item.date,
          description: item.description,
          asset_id: item.asset_id,
          asset_person_id: item.asset_person_id,
      };
    },
    store () {
      this.$v.$touch();

      if (this.isValid) {
        this.submitted = true;

        if(this.form.id > 0){
          this.$emit("update", this.form );
        }
        else{
          this.$emit("store", this.form );
        }

        this.closeModal();
      }
    },
    closeModal () {
      this.showModal = false;

      this.form = this.getEmptyForm()
      this.submitted = false
      this.$v.$reset()
    },
    getEmptyForm () {
      return {
          id: 0,
          description: "",
          date: moment(),
          asset_id: "",
          asset_person_id: "",
      }
    },
    checkIfValid (fieldName) {
      const field = this.$v.form[fieldName]
      if (!field.$dirty) {
        return null
      }
      
      return !(field.$invalid || field.$model === '')
    },
  },
  computed: {
    formString () { return JSON.stringify(this.form, null, 4) },
    isValid () { return !this.$v.form.$invalid },
    isDirty () { return this.$v.form.$anyDirty },
  },
  mixins: [validationMixin],
  validations: {
    form: {
      description: {        
      },
      date: {        
        required,
      },
      asset_id: {
        required,
        minValue: minValue(1)
      },
      asset_person_id: {
        required,
        minValue: minValue(1)
      },
    }
  },
}
</script>